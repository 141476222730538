import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const createImg = identifier =>
  `https://archive.org/download/${identifier}/__ia_thumb.jpg`

const createImdbUrl = imdb => `http://www.imdb.com/title/${imdb}/`
const createUrl = identifier => `https://archive.org/details/${identifier}/`

const PAGE_SIZE = 20

const file = '/search-pub-sm.csv'

const Controls = ({ page, setPage, totalpages, fltrTg, filter, bw }) => {
  const inc = page => {
    setPage(page <= PAGE_SIZE ? 0 : page - PAGE_SIZE)
  }
  const dec = page => {
    console.log('page', page)
    setPage(page + PAGE_SIZE)
  }

  return (
    <div className='controls'>
   {!!bw &&   <button onClick={() => bw()}>b/w</button>}

      <div>
        {page} of {totalpages}
      </div>
      <button onClick={() => inc(page)}>prev</button>
      <button onClick={() => dec(page)}>next</button>
      <button className={'fltr ' +(filter ? "active":"") }onClick={() => fltrTg()}>
        fltr
      </button>
    </div>
  )
}

const Item = ({ data }) => {
  const {
    title,
    subject,
    description,
    stripped_tags,
    rights,
    licenseurl,
    identifier,
    year
  } = data
  const [showRaw, setShowRaw] = useState(false)

  const [isSelected, setIsSelected] = useState(
    JSON.parse(localStorage.getItem(identifier)) || false
  )

  const handleToggle = () => {
    localStorage.setItem(identifier, JSON.stringify(!isSelected))

    setIsSelected(!isSelected)
  }

  let imdb

  if (stripped_tags) {
    var pattern = /imdb\.com\/title\/(tt\d+)\//

    var match = stripped_tags.match(pattern)

    if (match) {
      const imdbId = match[1]

      if (imdbId) {
        imdb = createImdbUrl(imdbId)
      }
    }
  }

  return (
    <div className='item'>
      <div>
        <a
          className='img'
          target='_blank'
          rel='noreferrer'
          href={createUrl(identifier)}
        >
          {
            <img
              src={createImg(identifier)}
              alt={title}
              onError={i => (i.target.style.display = 'none')}
            />
          }
        </a>
        <div className='details'>
          <div className='title'>
            {title}
            {!!year && <span>{year}</span>}
          </div>
          <div className='subject'>{subject}</div>
          <div className='description'>{description}</div>
          {!!imdb && (
            /**
           *   target="_blank" rel="noreferrer" 
          href={imdb} 
           */
            <a className='imdb' target='_blank' rel='noreferrer' href={imdb}>
              {imdb}
            </a>
          )}
          <div className=''>
            <button
              className='showRaw'
              onClick={() => {
                setShowRaw(!showRaw)
              }}
            >
              show/hide raw
            </button>
            <button
              className={'use ' + (isSelected ? 'active' : '')}
              onClick={() => {
                handleToggle()
              }}
            >
              use
            </button>
          </div>
        </div>
      </div>

      {!!showRaw && (
        <div className='raw'>{JSON.stringify(data, undefined, 2)}</div>
      )}
    </div>
  )
}

export const App = () => {
  const { pageId } = useParams()

  const [filter, setFilter] = useState(false)
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [totalpages, settotalpages] = useState(0)

const [isBw,setIsBw] = useState(false)

  useEffect(() => {
    if (!pageId) return

    try {
      const x =  parseInt(pageId) 
      setPage(isNaN(x) ? 0 : x)
    } catch{
      setPage(0)

    }
   
  }, [pageId])

  const fltrTg = () => {
    setFilter(!filter)
  }

const bw = () => {
  setIsBw(!isBw)
}

  useEffect(() => {
    window.history.replaceState(null, '', page)

    const csv = require('csvtojson')

    fetch(file)
      .then(r => r.text())
      .then(text => {
        csv({ flatKeys: true })
          .fromString(text)
          .then(jsonObj => {
            console.log(jsonObj)
            if (!jsonObj) return

            let short = jsonObj.slice(page, page + PAGE_SIZE)
            settotalpages(jsonObj.length)
            setItems(short)
          })
      })
  }, [page])

  return (
    <div className='content'>
                {!!isBw &&      <link rel="stylesheet" type="text/css" href={"/bw.css"} />}

      <Controls
        page={page}
        setPage={setPage}
        totalpages={totalpages}
        fltrTg={fltrTg}
        filter={filter}
        bw={null}

      />
      {!!items?.length &&
        items
          .map(item => {
            if (!!filter) {
              const cond =
                JSON.parse(localStorage.getItem(item.identifier)) || false
              if (cond) {
                return <Item key={item.identifier} data={item} />
              } else {
                return false
              }
            }

            return <Item key={item.identifier} data={item} />
          })
          .filter(Boolean)}
      <Controls
        page={page}
        setPage={setPage}
        totalpages={totalpages}
        fltrTg={fltrTg}
        filter={filter}
        bw={bw}
      />
    </div>
  )
}
